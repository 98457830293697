.nugget {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  cursor: pointer;

  &:hover {
    background-color: rgb(199, 197, 197);
  }

  width: calc(25% - 15px); /* 25% width for each nugget with spacing */
  height: 120px; /* Fixed height for each nugget */
  box-sizing: border-box; /* Include padding and borders in the width calculation */

  @media screen and (max-width: 968px) {
    // width: 100%; /* Full width for each nugget on screens <= 768px */
    width: calc(50% - 15px); /* Full width for each nugget on screens <= 768px */
  }

  &_title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;

    height: 50px; /* 60px for the title */
    overflow: hidden; /* Hide overflow text */
  }

  &_content {
    font-size: 26px;
    font-weight: bolder;

    height: 50px; /* 40px for the value */
    overflow: hidden; /* Hide overflow text */
  }
}
