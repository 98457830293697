.tableContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;

  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #7E4E77;
    color: white;
    border-color: #f2f2f2;
  }
  
  .superHeading {
    text-align: center;
  }
  
  .evenRow {
    background-color: #fdf9f9; /* Background color for even rows */
  }
  
  .oddRow {
    background-color: #f2f2f2;
  }

}

