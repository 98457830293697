@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
#root {
  font-family: "Roboto", sans-serif;
  @media screen and (max-width: 960px) and (orientation: portrait) {
    height: 100vh;
  }
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

@keyframes run {
  0% {
    left: -90px;
    color: #61dbfb;
  }
  50% {
    color: #ade8f7;
  }
  100% {
    left: 90px;
    color: #61dbfb;
  }
}

.PhoneInputInput {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  border: none;
  padding: 2px 10px;
  font-size: 18px;
  color: #7e98b2;

  &:focus {
    outline: none;
    border: none;
  }

}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(159, 158, 255);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(167, 185, 181);
  border-radius: 10px;
}