.authBody {
    box-sizing: border-box;
    background-color: #f1f3f9;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &_errorMessage {
        color: red;
        font-weight: 300;
    }
    &_popUp {
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &__successText {
            color: #7e98b2;
            font-size: 17px;
        }
        &__closeIcon {
            position: absolute;
            top: 20px;
            right: 25px;
            cursor: pointer;
            font-size: 20px;
        }
        &__box {
            background-color: white;
            width: 91%;
            max-width: 450px;
            padding: 20px 10px;
            text-align: center;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.13);
            position: relative;
        }
        &__header {
            font-weight: 400;
            color: #172b4d;
            margin-bottom: 25px;
        }
        &__subText {
            margin-bottom: 30px;
            color: #7e98b2;
        }
    }
}

.authContainer {
    display: flex;
    height: calc(100vh - 60px);
    padding: 5px;
    align-items: center;
    justify-content: center;
    &_FormContainer {
        border: 0.5px solid #cdd2db;
        display: flex;
        width: 600px;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;
        padding: 50px 10px;
        justify-content: center;
        align-items: center;
        & form {
            width: 400px;
        }
        &__header {
            color: #172b4d;
            font-size: 30px;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 40px;
            text-align: center;
        }
    }
    &_submitBtn {
        color: rgb(68, 68, 68);
        background-color: #f1f1f115;
        border: 1px solid #172b4d;
        min-width: 150px;
        padding: 12px 25px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        border-radius: 10px;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        &:focus {
            outline: none;
        }
        &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
        }
    }
    &_linkText {
        font-size: 15px;
        margin-top: 25px;
    }
    &_link {
        color: #768bb2;
        font-size: 14px;
        font-weight: 500;
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 25px;
    @media screen and (max-width: 700px) {
        align-items: center;
    }
    &_label {
        margin-bottom: 10px;
        color: #434343;
        font-size: 15px;
    }
    &_input {
        box-sizing: border-box;
        width: 100%;
        max-width: 400px;
        border: 1px solid #818181;
        border-radius: 5px;
        padding: 8px 10px;
        font-size: 18px;
        color: #434343;
        &__forgotPassword {
            text-align: center;
            margin-bottom: 20px;
        }
        &:focus {
            outline: none;
            border: 1px solid #353535;
        }
    }
    &_smallBtn {
        color: #768bb2;
        margin: 0;
        margin-top: 8px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }
}

.signupContainer {
    width: 80%;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    border: 0.5px solid rgb(197, 196, 196);
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    & h1 {
        text-align: center;
        margin-bottom: 20px;
    }
    & ol,
    ul {
        margin-left: 20px;
    }
    & label {
        display: block;
        margin: 10px 0 5px;
    }
    & input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
    }
    .btn {
        display: block;
        width: 100%;
        padding: 10px 20px;
        background-color: #ff8c69;
        color: white;
        text-decoration: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
        &:hover {
            background-color: #ff6a50;
        }
    }
}

.successCard {
    background-color: rgb(195, 255, 195); // Greenish background color
    border-radius: 10px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    gap: 20px;
    margin: 50px 400px;
    @media screen and (max-width: 767px) {
        margin: 50px 10px;
    }
    .image {
        color: rgb(4, 134, 4);
        width: 80px;
        height: 80px;
    }
    .message {
        font-size: 18px;
        font-weight: bolder;
        text-align: center;
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}