.questionsData {
  .category {
    margin-bottom: 20px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
      }

      th {
        background-color: #f0f0f0;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
}
