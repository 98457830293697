.tableContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.tableColumn {
  width: 48%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
}

.tableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 10px;
  
  &:nth-child(even) {
    background-color: #f2f2f2; /* Apply different color for even rows */
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
}

.heading {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;

  @media (max-width: 768px) {
    flex-direction: row;
  }
}

.question {
  flex: 0.4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.answer {
  flex: 0.6;
  text-align: right;
  word-break: break-all;
}
