.radioContainer {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .radioCheckmark {
    width: 25px;
    height: 25px;
    border: 2px solid rgb(126, 78, 119);
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;

    &:hover {
      border-color: #ccc;
    }
  }

  input:checked + .radioCheckmark {
    border-color: rgb(126, 78, 119);
    background-color: rgb(126, 78, 119);

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background: white;
      border-radius: 50%;
    }
  }
}
