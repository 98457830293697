.title {
  color: rgb(48, 36, 61);
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bolder;
  text-align: center;
  font-size: 48px;

  @media screen and (max-width: 700px) {
    font-size: 28px;
  }
}

.content {
  margin-bottom: 20px;
  color: #222;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  margin-top: 50px;

  .footerContainer {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .footerSection {
      flex: 1;
      padding: 0 10px;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
      li {
        margin: 5px 0;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

.imageColumn {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  padding: 0px 30px;

  & img {
    flex: 1 0 20%;
    width: 200px;
    height: auto;
    border-radius: 25px;
  }
}

.gradient {
  background: rgb(229,251,246);
  background: linear-gradient(90deg, rgba(229,251,246,1) 0%, rgba(153,207,218,1) 100%);
  display: flex;
  justify-content: space-between;
  padding: 0px 300px;
  align-items: center;

  @media screen and (max-width: 700px) {
    padding: 0px 10px;
  }

  .rightImage {
    height: 100%;

    @media screen and (max-width: 700px) {
      height: 400px;
      margin-right: -20px;
      margin-left: -40px;
    }
  }

  .title {
    font-size: 66px;
    color: rgb(48, 36, 61);
    font-weight: bolder;
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 28px;
    }
  }
  .subtitle {
    font-size: 22px;
    color: rgb(48, 36, 61);
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
}

.serviceSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 300px;

  @media screen and (max-width: 700px) {
    padding: 0px 10px;
  }

  .title {
    font-size: 38px;

    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }

  .circleGradient {
    padding: 20px;
    flex: 1 0 25%;
    margin: 20px;
    width: 100%;
    border-radius: 10px;
    background: rgb(227,238,236);
    background: linear-gradient(90deg, rgba(227,238,236,1) 0%, rgba(196,201,240,1) 100%);
    text-align: center;

    @media screen and (max-width: 700px) {
      padding: 10px;
      flex: 1 0 50%;
    }
  }
  
}

