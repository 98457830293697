/* custom-calendar.css */
.rbc-header {
  background-color: rgb(126, 78, 119);
  color: white;
  font-size: 16px;
  padding: 10px;
}

.rbc-button-link {
  font-size: 16px; /* Adjust the font size as needed */
}

.rbc-day-bg {
    cursor: pointer;
}
