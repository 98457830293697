.mainDashboard {
  // only for dashboard page, so that the loader shows in the center
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mainContent {
  padding: 0px 300px;

  .nuggetContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Distribute items evenly in the row */
    max-width: 100%; /* Make sure it doesn't exceed the container's width */
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .chartContainer {
    display: flex;
    margin-top: 30px;
    background-color: #fcf7f7;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.062);

    .labelContainer {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      max-height: 350px;
      overflow: auto;
      user-select: none;
      flex: 0.4;
      scrollbar-width: 6px;

      &::-webkit-scrollbar {
        width: 6px; /* Set the width of the scrollbar */
      }
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 0px 10px;
  }

  &_tableContainer {
    margin-top: 30px;
    max-height: 700px;
    overflow: auto;
    padding: 2px 2px;
  }

  &_table {
    border: 1px solid;
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #666;

    td, th {
      border: 1px solid;
      padding: 20px 0px;
      text-align: center;
      min-width: 40px;
    }
  }
  
  &_formContainer {
    margin-top: 30px;
    padding: 2px 2px;
    margin-bottom: 30px;
    margin: 0 300px;
    padding: 20px;
    border: 0.5px solid rgb(197, 196, 196);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    @media screen and (max-width: 767px) {
      margin: 0 10px;
    }

    .column {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    & h2 {
      color: #707070;
    }
  }
}

.stepHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  h2 {
    text-align: center;
  }

  &_buttons {
    display: flex;
    gap: 5px;
  }
  
}

.questionContainer {
  max-height: calc(100vh - 300px);
  padding-right: 5px;
  overflow: auto;
}

.questionRow {
  display: flex;
  justify-content: space-between;
  padding: 30px 5px 0px 5px;
  margin-bottom: 5px;
  background-color: rgba(240, 240, 240, 0.774);
  border-radius: 10px;

  &_questionText {
    flex: 0.6;
    margin-bottom: 5px;
    font-weight: bold;
  }
  &_questionOptions {
    flex: 0.4;
    display: flex;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}


.rightAligned {
  display: flex;
  justify-content: flex-end;
}

.patientDetailsLink {
  cursor: pointer;
  color: rgb(94, 122, 247);
  font-weight: bold;
}

.patientDetails {
  display: flex;
  flex-direction: column;

  &_table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 5px 5px 3px #dddddd;
    background-color: rgb(247, 247, 247);

    td, th {
      padding: 20px 10px;
      text-align: center;
      min-width: 40px;
      max-width: 70px;
      word-wrap: break-word;
      text-align: left;
    }

    .btnAction {
      cursor: pointer;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 10px;
      outline: none;
      border: none;
      background-color: #7E4E77;
      color: white;
      // min-width: 100%;
    }
  }

  h2 {
    color:rgb(86, 86, 87)
  }

  &_basicDetails {
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    background-color: rgb(247, 247, 247);
    border-radius: 10px;
    box-shadow: 5px 5px 3px #dddddd;
    padding: 10px 10px;
    column-gap: 50px;

    @media screen and (max-width: 767px) {
      column-gap: 10px;
    }

    &_left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
    }
    &_right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
    }

  }
    li {
      list-style-type: none;
      padding: 10px 0px;
    }
}
