.checkboxContainer {
  // define constants
  --bc: rgb(126, 78, 119);

  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;

  input {
    display: none;
  }

  .checkboxCheckmark {
    width: 22px;
    height: 22px;
    border: 2px solid var(--bc);
    background-color: white;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;

    &:hover {
      border-color: #ccc;
    }
  }

  input:checked + .checkboxCheckmark {
    border-color: var(--bc);
    background-color: var(--bc);

    &::after {
      content: "\2713"; // Checkmark Unicode character
      color: white;
      font-size: 14px;
    }
  }
}
