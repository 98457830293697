.questionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 5px;
  gap: 10px;
  background-color: rgb(232, 238, 226);

  &_questionText {
    flex: 0.4;
    font-weight: bold;
  }
  &_questionOptions {
    display: flex;
    flex: 0.6;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
  }

  textarea {
    height: 110px;
    font-size: 18px;
  }

  .inputField {
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid rgb(126, 78, 119);
    outline: none;
    flex: 0.6;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}