.notificationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  .notificationContainer {
    display: flex;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    
    &.unread {
      box-shadow: 0 2px 4px rgba(255, 0, 0, 0.4); /* Reddish shadow */
      font-weight: bolder;
      cursor: pointer;

      &:hover {
        background-color: rgba(61, 165, 35, 0.1);
      }
    }

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .date {
      flex: 0.1;
      font-size: 14px;
      font-weight: bolder;
    }

    .content {
      flex: 0.9;
      padding-left: 10px;

      h3 {
        font-size: 18px;
        margin: 0;
      }

      div {
        font-size: 16px;
        color: #333;
      }
    }
  }
}
