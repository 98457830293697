.questionSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 22px;
      color: rgb(32, 31, 31);
    }

    .controls {
      display: flex;
      gap: 15px;
    }
  }

  .content {
    overflow: auto;
    background-color: rgba(236, 241, 245, 0.774);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 50px;
  }
}



