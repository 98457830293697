.questionSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .controls {
      display: flex;
      gap: 15px;

      button {
        padding: 12px 20px;
        background: rgb(126, 78, 119);
        color: white;
        font-weight: bold;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        font-size: 15px;

        &:disabled {
          cursor: not-allowed;
          background: rgb(141, 135, 140);
        }
      }
    }
  }

  .content {
    background-color: rgba(236, 241, 245, 0.774);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 15px;
  }
}
