.dashboard {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
    &_nightMode &_main {
        background-color: #262626 !important;
    }
    &_main {
        background-color: #f1f3f9;
        flex: 1;
        max-width: calc(100% - 52px);
        order: 1;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 700px) {
            max-width: 100%;
        }
        &_top {
            padding: 30px;
            position: relative;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media screen and (max-width: 750px) {
                padding: 15px;
            }
            @media screen and (max-width: 560px) {
                padding: 10px;
            }
        }
        &_logos {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            & img {
                width: 200px;
                max-height: 110px;
                object-fit: contain;
                @media screen and (max-width: 560px) {
                    margin-top: 10px;
                    width: 170px;
                }
            }
        }
    }
}

.authBody {
    background-color: #f1f3f9;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    &_nightMode {
        background-color: #262626 !important;
    }
    &_nightMode .whiteBG {
        background-color: #1a1a1a;
    }
    &_nightMode .headerContainer_links a {
        color: white;
        background-color: #1a1a1a;
    }
}

.headerContainer {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    gap: 20px;
    img {
        width: 60%;
        @media only screen and (max-width: 700px) {
            width: 96%;
            padding: 0 2%;
        }
    }
    &_themeToggle {
        cursor: pointer;
        user-select: none;
        & img {
            width: 25px;
        }
    }
    &_links {
        display: flex;
        align-items: center;
        @media screen and (max-width: 700px) {
            margin-bottom: 15px;
        }
        & a {
            text-decoration: none;
            font-weight: 500;
            color: #172b4d;
            text-align: center;
            margin-right: 20px;
            & span {
                @media screen and (max-width: 410px) {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        width: 70%;
    }
    @media screen and (max-width: 500px) {
        width: fit-content;
        align-self: center;
    }
    @media screen and (max-width: 700px) {
        justify-content: center;
        flex-direction: column;
    }
}

.siteLogo {
    width: 160px;
    user-select: none;
}

.whiteBG {
    background-color: white;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.144);
    @media screen and (max-width: 700px) {
        padding: 0px 10px;
    }
}

.navbar {
    display: flex;
    width: 100%;
    color: white;
    background-color: rgb(126, 78, 119);
    align-items: center;
    justify-content: space-between;
    &_logo {
        font-size: 20px;
        font-weight: bold;
    }
    &_link:not(:last-child) {
        margin-right: 20px;
    }
    & a {
        text-decoration: none;
        color: white;
    }
    & li {
        color: white;
        list-style: none;
        display: inline;
        cursor: pointer;
    }
}