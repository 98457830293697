.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds_ring {
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 70px;
        height: 70px;
        margin: 8px;
        border: 8px solid #0d6efd;
        border-radius: 50%;
        animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #0d6efd transparent transparent transparent;
    }

    & div:nth-child(1) {
        animation-delay: -0.45s;
    }

    & div:nth-child(2) {
        animation-delay: -0.3s;
    }

    & div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes lds_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}