$select-color: rgb(68, 68, 68);
$selected-color: rgb(14, 14, 14);
$select-background: #dfe9e8;
$select-width: 100%;
$select-height: 40px;

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  color: $select-color;
  width: $select-width;
  height: $select-height;
}
.selectStyled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $select-background;
  padding: 8px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: $select-color transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
  }
  &:hover {
    background-color: darken($select-background, 2);
  }
  &:active,
  &.active {
    background-color: darken($select-background, 5);
    &:after {
      top: 9px;
      border-color: transparent transparent $select-color transparent;
    }
  }
}

.form {
  &_group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;

    &_center {
      align-items: center !important;
    }

    @media screen and (max-width: 700px) {
      align-items: center;
    }
  }

  &_group_center &_input {
    text-align: center !important;
  }
  &_label {
    margin-bottom: 10px;
    color: #434343;
    font-size: 15px;
  }
  &_input {
    box-sizing: border-box;
    width: 90%;
    max-width: 400px;
    border: 1px solid #818181;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 18px;
    color: #434343;
    @media screen and (max-width: 700px) {
      text-align: center;
    }
    &:focus {
      outline: none;
      border: 1px solid #353535;
    }
  }
  
  &_button {
    background-color: #7E4E77;
    min-width: 150px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    &:hover {
      background-color: #704269;
    }

    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  &_message {
    &__error {
      color: red;
      display: block;
      font-size: 14px;
      font-weight: bold;
      margin-top: 5px;
      p {
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
    &__success {
      color: green;
      font-weight: bold;
      display: block;
    }
    
  }
}


.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;

  &_label {
    margin-bottom: 10px;
    color: #434343;
    font-size: 15px;
  }
  &_errorMessage {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    color: #f12d68;
  }

  &_successMessage {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    color: #08a54f;
  }

  &_input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #bebebe;
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 18px;
    color: #434343;

    &:focus,:active {
      border: 2px solid #2684FF;
      outline: none;
    }
  }

  &_submitBtn {
    font-weight: 600;
    color: white;
    background-repeat: no-repeat;
    background-position: 0% 90%;
    background-size: cover;
    border-radius: 20px;
    border: none;
    min-width: 150px;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 16px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    &:focus {
      outline: none;
      background-color: #00a2ec;
    }
    &:hover {
      background-color: #078ecc;
    }
    &:disabled {
      background-color: #61c0ec;
      cursor: not-allowed;
    }
  }
}