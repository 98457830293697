.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & h1 {
      color: #f15d5e;
      font-size: 120px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    & p {
      margin-top: 0px;
      font-size: 32px;
    }

  }
}
