.setting {
  display: flex;
  padding: 0 300px;
  width: 100%;
  min-height: 500px;
  justify-content: center;
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    padding: 0 10px;
  }

  &_right {
    position: relative;
    background-color: white;
    flex: 0.5;
    border-radius: 10px;
    border: 1px solid rgb(70, 82, 151);
    padding: 15px 30px;
    padding-top: 0;
    margin-top: 70px;

    @media screen and (max-width: 900px) {
      padding: 50px 20px;
      padding-top: 0;
    }

    @media screen and (max-width: 850px) {
      flex: 1;
    }
  }

  &_connectForm {
    &__header {
      color: #172b4d;
      font-weight: 400;
      border-bottom: 1px solid #bcbcbc;
      padding-bottom: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__message {
      color: #f76277;
      font-weight: 400;
    }

    & form {
      display: flex;
      flex-direction: column;

      .column {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }
  }
}


.listWrap {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 23px;
  box-sizing: border-box;
  transform: translateY(-100%);

  &_item {
    padding: 10px 0;
    flex: 0.5;
    margin-right: 1px;
    color: #504f4f;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px 7px 0 0;
    box-sizing: border-box;
    background-color: #cdcfd3;

    &__active {
      font-weight: 600;
      background-color: #abaeb3;
      border: none;
    }

    &:active,
    &:focus,
    &:hover {
      font-weight: 600;
      background-color: #cdcfd3;
      border: none;
    }
  }
}
