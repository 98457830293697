.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.144);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background-color: rgb(255, 255, 255);
  width: calc(100% - 600px);
  height: calc(100vh - 200px);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 700px) {
    width: calc(100% - 20px);
    padding: 20px 5px;
  }

  &_header {
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
  }

  .popupClose {
    cursor: pointer;
    font-size: 28px;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 0px;
  }
}